:root {
    --fc-primary: rgb(38, 38, 38);
    --fc-gray-800: rgb(66, 66, 66);
    --fc-gray-400: rgb(203, 203, 203);
    --fc-gray-200: rgb(247, 247, 247);

    --bg-accent: #0067b3;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

a:not(.nav-link) {
    text-decoration: none;
    color: var(--fc-primary);
}

.link:not(.nav-link) {
    color: blue;
}

.page-link:not(.nav-link) {
    color: blue;
}

.nav-link {
    text-decoration: none !important;
}
.page-link:hover {
    text-decoration: underline;
}

.embed-youtube {
    position: relative;
    padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 2rem;
    margin: 3rem;
}

.modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gallery-photo {
    width: 350px;
    height: auto;
}
